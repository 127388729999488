import { CMSCroppedImage } from 'components/images/Image';
import { FwPakkeImageDocument, ICultureArticle } from 'lib/types/sanity';
import Link from 'next/link';
import styled from 'styled-components';

//#region [Styles]
const SCultureShow = styled.article`
`;

const SImageContainer = styled.a`
	position: relative;
	display: block;
	box-sizing: content-box;
	aspect-ratio: 16 / 9;
	background-color: #fefefe;
	border-bottom: 7px solid var(--color_posters_border, var(--color_primary));
`;

const SImageMissing = styled.div`
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translateY(-50%);
	color: black;
	&::before {
		display: block;
		text-align: center;
		content: "Bilde mangler";
	}
`;

const SCultureImage = styled(CMSCroppedImage)`
	display: block;
	width: 100%;
	height: auto;
`;

const STitleContainer = styled.div`
	position: relative;
	margin: 0;
	padding: 15px 5px 0 15px;
	background-color: var(--color_fpposter_bg_mobile_culture, #fefefe);


	display: flex;
	gap: 15px;
	align-items: flex-start;

	@media screen and (min-width: 641px ) {
		background-color: var(--color_fpposter_bg_culture, #fefefe);
	}

	h1 {
		margin: 0;
		padding-bottom: 15px;
		flex: 1 1 auto;
		font-size: 1rem;
		a {
			color: var(--color_fpposter_text_mobile_culture, #000000);
			@media screen and (min-width: 641px ) {
				color: var(--color_fpposter_text_culture, #000000);
			}
		}

	}
`;


//#endregion

//#region [Props]
type OneCultureShowProps = {
	article: ICultureArticle;
	className?: string;
};
//#endregion

//#region [Component]
export default function OneCultureShowWithoutTime({ article, className }: OneCultureShowProps) {
	const url = `/kulturprogram/${article.kulnr.current}`;
	return <SCultureShow className={className}>
		<Link href={url} passHref legacyBehavior>
			<SImageContainer >
				{article?.image && <SCultureImage image={article.image as FwPakkeImageDocument} width={470} heightRatio={0.5625} sizes="(min-width: 641px) 470px, 100vw" />}
				{!article?.image && <SImageMissing />}
			</SImageContainer>
		</Link>
		<STitleContainer>
			<h1><Link href={url}>{article.titleOverride ?? article.title}</Link></h1>
		</STitleContainer>
	</SCultureShow>;
}


//#endregion