import { Filmomtale } from '@filmweb/sanity-types';
import SArticleTitle from "components/framework/SArticleTitle";
import SMaxWidthContainer from 'components/framework/SMaxWidthContainer';
import CMSImage, { OgImage } from 'components/images/Image';
import RelatedMovies from 'components/nyhet/RelatedMovies';
import RichText, { QUERY_FRAGMENT_BODYTEXT, QUERY_FRAGMENT_INGRESS } from 'components/richtext/RichText';
import { useDocumentTitle } from 'lib/client/useTitle';
import { setDefaultCacheHeaders } from 'lib/server/cache';
import { toRem } from "lib/client/fonts";
import { fwpakkeQuery, getFilmwebFilmomtale, getServerData } from 'lib/server/sanity';
import { DocumentType_CULTURE, DocumentType_MOVIE, DocumentType_NEWS, FwPakkeImageDocument, ICommonProps, ICultureArticle, IMovieArticle, INewsArticle, IQueryResult } from 'lib/types/sanity';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { groq } from 'next-sanity';
import Head from 'next/head';
import styled from 'styled-components';
import getConfig from 'next/config';
import PublicRuntimeConfig from 'lib/types/PublicRuntimeConfig';

const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: PublicRuntimeConfig };

//#region [Other]
const PAGE_QUERY: string = groq`
{
	"published": *[_type =="${DocumentType_NEWS}" && slug.current == $slug && !(_id in path("drafts.**"))] {
		...,
		${QUERY_FRAGMENT_BODYTEXT},
		${QUERY_FRAGMENT_INGRESS},
		image->,
		movies[]-> {
			...,
			image->
		}
	}[0],
	"preview": *[_type =="${DocumentType_NEWS}" && slug.current == $slug && _id in path("drafts.**")] {
		...,
		${QUERY_FRAGMENT_BODYTEXT},
		${QUERY_FRAGMENT_INGRESS},
		image->,
		movies[]-> {
			...,
			image->
		}
	}[0]
}
`;
//#endregion

//#region [Other]getServerSideProps
export const getServerSideProps: GetServerSideProps = getServerData(async (context) => {
	const slug = context.params?.slug;
	if (!slug) {
		console.warn("Missing slug");
		return { notFound: true };
	}

	setDefaultCacheHeaders(context as GetServerSidePropsContext);

	const pageData = await fwpakkeQuery<IQueryResult<INewsArticle>>(PAGE_QUERY, { slug }, !context.preview);

	const ctx = context as GetServerSidePropsContext;
	const isP = ctx.query?.p === "1";
	const document = context.preview && isP ? (pageData?.preview ?? pageData?.published) : pageData?.published;
	console.debug("Preview", context.preview);
	if (!document) {
		console.warn("Article not found", slug);
		return { notFound: true };
	}

	if (!isP && document.slug?.current !== slug) {
		console.warn("Article found, but slug does not match latest published slug", slug);
		return { notFound: true };
	}

	let filmwebMovies: Filmomtale[] | undefined;

	if ((document?.movies?.length ?? 0) > 0) {
		const edis = document!.movies.map(movie => {
			if (movie._type === DocumentType_MOVIE) {
				return (movie as IMovieArticle)?.edi?.current
			}
			return null;

		})?.filter(edi => !!edi);
		if ((edis?.length ?? 0) > 0) {
			filmwebMovies = await getFilmwebFilmomtale(edis as string[]);
			// order the movies so they are in the same order as the article
			filmwebMovies?.sort((a, b) => edis.indexOf(a.mainVersionEDI) - edis.indexOf(b.mainVersionEDI));
		}
	}

	return {
		props: {
			document,
			relatedMovies: filmwebMovies ?? null
		}
	};

});
//#endregion


//#region [Styles]
const SArticleImage = styled(CMSImage)`
	margin: 0 auto 50px auto;
	display: block;
	width: auto;
	height: 100%;
	max-height: 600px;
`;

const SIngress = styled(RichText)`
	font-size: 1rem;
	margin: 50px 0;
	font-weight: var(--font_bold);
	margin: 0;

	@media screen and (min-width: 641px) {
		padding: 0;
		font-size: ${toRem(21)};
	}
`;


const STextContainer = styled(SMaxWidthContainer)`
	margin-bottom: 50px;
	padding-left: 10px;
	padding-right: 10px;
`;

//#endregion


//#region [Props]
type NewsProps = {
	document: INewsArticle;
	relatedMovies?: Filmomtale[];
} & ICommonProps;
//#endregion

//#region [Component]
export default function News({ document, appData, relatedMovies }: NewsProps) {
	const title = useDocumentTitle(appData.settings, document);

	return (<SMaxWidthContainer as="article">
		<Head>
			<title>{title}</title>
			<meta property="og:title" content={title} key="title" />
			<meta property="og:url" content={`${publicRuntimeConfig?.NEXT_PUBLIC_BASE_URL}informasjon/${document?.slug.current}`} key="ogUrl" />
		</Head>
		<OgImage document={document} />
		<SArticleTitle>{document.title}</SArticleTitle>

		{document.image && <SArticleImage width={640} image={document.image as FwPakkeImageDocument} sizes="(min-width: 1440px) 1440px, 100vw" />}

		<STextContainer $forContent>
			<SIngress value={document.ingress} />
			<RichText value={document.bodyText} />
		</STextContainer>

		<RelatedMovies movies={relatedMovies} localMovies={document.movies?.filter(m => m._type === DocumentType_CULTURE) as ICultureArticle[]} />

	</SMaxWidthContainer>);
}
//#endregion