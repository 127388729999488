import { toRem } from 'lib/client/fonts';
import styled from 'styled-components';
//#region [Styles]
const SArticleTitle = styled.h1`
	text-align: center;
	font-size: ${toRem(24)};

	@media screen and (min-width: 641px) {
		font-size: ${toRem(48)};
	}
`;

export default SArticleTitle;
//#endregion
