import { Filmomtale } from '@filmweb/sanity-types';
import OneCultureShowWithoutTime from "components/frontpage/culture/OneCultureShowWithoutTime";
import MoviePoster from 'components/movieposter/MoviePoster';
import { ICultureArticle, IMovieArticle } from "lib/types/sanity";
import styled from 'styled-components';


//#region [Styles]

const SMovieGrid = styled.div`
	padding: 0 10px 50px 10px;
	margin: 0 auto;

	display: grid;
	gap: 10px;
	grid-template-columns: repeat(3, minmax(0, 1fr));

	@media screen and (min-width: 641px) {
		gap: 16px;
		grid-template-columns: repeat(5, minmax(0, 1fr));
		max-width: 800px;
	}
`;

const SMovie = styled.article`
	background-color: var(--color_fpposter_bg_mobile, var(--color_fpposter_bg, #fefefe));

	@media screen and (min-width: 641px ) {
		background-color: var(--color_fpposter_bg, #fefefe); // default to standard color if the inversion is not set
	}
`;

const SCultureGrid = styled.div`
	padding: 0 10px 50px 10px;
	margin: 0 auto;

	display: grid;
	gap: 10px;
	grid-template-columns: repeat(1, minmax(0, 1fr));

	@media screen and (min-width: 641px) {
		gap: 16px;
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
`;

const SCultureItem = styled(OneCultureShowWithoutTime)``;

//#endregion

//#region [Props]
type RelatedMoviesProps = {
	movies?: Filmomtale[];
	localMovies?: ICultureArticle[]
};
//#endregion

//#region [Component]
export default function RelatedMovies({ movies, localMovies }: RelatedMoviesProps) {
	if ((movies?.length ?? 0) === 0 && (localMovies?.length ?? 0) === 0) {
		return null;
	}
	return (<div>
		{(localMovies?.length ?? 0) > 0 && <SCultureGrid>
			{localMovies!.map(kul => <SCultureItem key={kul.kulnr.current} article={kul} />)}
		</SCultureGrid>}
		{(movies?.length ?? 0) > 0 && <SMovieGrid>
			{movies?.map((movie) => {
				return (<SMovie key={movie.mainVersionEDI}><MoviePoster edi={movie.mainVersionEDI} filmomtale={movie} sizes="(min-width: 800px) 145px, 116px" /></SMovie>)
			})}
		</SMovieGrid>}
	</div>);
}
//#endregion